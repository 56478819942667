<template>
    <div class="all-body">
        <leftContent></leftContent>
        <amongContent></amongContent>
        <rightContent></rightContent>
    </div>
</template>

<script>
import leftContent from '../components/technology/leftContent.vue';
import rightContent from '../components/technology/rightContent.vue';
import amongContent from '../components/amongContent.vue';
export default {
    name:'technology',
    data(){
        return{

        }
    },
    components: {
        leftContent,
        rightContent,
        amongContent
    }
}
</script>

<style scoped lang="scss">
.all-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
</style>